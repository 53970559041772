import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import _ from 'lodash-core'
import requests from '../requests'
import {
  compareConversations,
  conversationFormatter,
} from '~/utils/messaging/conversation'
import {
  ROOMS_URL,
  SEND_MESSAGE_URL,
  ROOMS_MESSAGE,
  ROOMS_NOTES,
  CONTACTS_URL,
  CONTACT_BLOCK,
  UPLOAD_URL,
  TICKET_URL,
  ROOMS_URL_V1_1,
  CONTACTS_HANDLERS,
  CONTACTS_OBJECT,
  IG_COMMENT_ROOMS_MESSAGE,
  REPORTS_URL,
} from '@/assets/variables/endpoints'

export const state = () => ({
  callPermissionRequest: {
    childContact: '',
    contactId: '',
  },
  isContactBlocked: false,
  formattedMessage: [],
  agentLists: [],
  roomSession: moment(),
  replyMessage: null,
  isCombineConvAndChatHistory: false,
  profileInformation: {
    id: '',
    handlerId: '',
    fullName: '',
    company: [],
    accountUniqIds: [],
    channels: [],
    channelIntegrations: [],
    customFields: [],
    contactIds: [],
  },
  contactCustomFields: [],
  conversationInformation: {
    id: '',
    contactId: '',
    name: '',
    channel: '',
    channelIntegrationId: '',
    email: null,
    phone: null,
    usageDetails: {
      created: '',
      last_seen: '',
      location: '',
      device: '',
      url_referrer: '',
      session_expired: '',
    },
    profileInformation: false,
    avatar: '',
    notes: '',
    tags: [],
    properties: [],
    agents: [],
    participants: [],
    resolved: false,
    session: '',
    last_message: {
      text: '',
      type: '',
    },
    unread_count: 0,
    temporaryRoomId: '',
    roomHistory: [
      {
        id: 0,
        broadcast: {
          type: '',
          text: '',
          url: '',
        },
        created_at: `${moment().subtract(37, 'hours').format()}`,
      },
    ],
    isManualSurvey: false,
    has_sla_list: false,
    type: '',
    lastActivityAt: '',
  },
  contactDetailData: {
    account_uniq_id: '',
    avatar: '',
    channel: '',
    channel_integration_id: '',
    childs: '',
    code: '',
    contact_handler_id: '',
    created_at: '',
    email: '',
    error_messages: '',
    extra: '',
    full_name: '',
    id: '',
    is_blocked: '',
    is_valid: '',
    last_activity_at: '',
    phone_number: '',
    status: '',
    updated_at: '',
    username: '',
    authority: '',
  },
  activeRooms: [],
  chatRoomDetails: {
    accountUniqId: '',
    name: '',
    channel: '',
    channelAccountName: '',
    channelIntegrationId: '',
  },
  broadcastDetail: {
    broadcast_name: '',
    message_template_name: '',
    created_at: `${moment().subtract(37, 'hours').format()}`,
    status: '',
    message: {
      type: '',
      text: '',
    },
  },
  editMode: {
    email: false,
    notes: false,
    name: false,
  },
  payloadToSend: {},
  trigger: {
    image: false,
    document: false,
  },
  meta: {},
  message: {
    length: null,
    pop: 0,
  },
  status: {
    noConversation: false,
    noResults: false,
    error: false,
  },
  page: 1,
  identifier: 1,
  conversationsFormatted: [],
  conversations: [],
  conversationHistories: [],
  query: {
    room: null,
    roomHistory: null,
  },
  scroll: {
    difference: null,
  },
  room: {
    unread: 1,
    isReadingAllMessages: false,
  },
  contact: {
    name: null,
    email: null,
    phone: null,
    whatsapp: null,
  },
  indexOfConversationLength: false,
  roomType: '',
  roomTicket: null,
  isTicketingActivated: false,
  messageQueue: [],
  isQueuing: false,
  reRender: 0,
  enable_sla_inbox: false,
})

export const getters = {
  getField,
  getQueryRoom(state) {
    return state.query.room
  },
}

export const actions = {
  async sendMessage({ commit, dispatch, state }, params) {
    if (!params.room_id) {
      throw new Error('Room ID is required')
    }

    // Adjust the current params to the formatted Request
    const createdAt = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSS[Z]')
    let data = {}

    if (params.reply) {
      data = {
        ...params,
        reply_id: params.reply.id,
      }
      delete data.reply
    } else {
      data = { ...params }
    }

    // ensure this func (postCallPermissionRequest) not stored at our global state
    if (data.postCallPermissionRequest) {
      delete data.postCallPermissionRequest
    }

    await commit(`${params.type.toUpperCase()}_FORMATTER`, data)
    // Update to local conversation
    const dateId = params.type + createdAt
    await commit(`UPDATE_${params.type.toUpperCase()}_CONVERSATION`, {
      ...state.formattedMessage,
      local: true,
      buttons: [],
      id: dateId,
      reply: params.reply,
    })

    // Define channel
    let channels = state.conversationInformation.channel.toLowerCase()
    if (channels === 'fb') {
      channels = 'fb_messenger'
    } else if (channels === 'ig') {
      channels = 'instagram'
    } else if (channels === 'web_chat') {
      channels = 'webchat'
    } else if (channels === 'wa') {
      channels = 'whatsapp'
    }

    const payload = {
      ...state.formattedMessage,
      payloadToSend: state.payloadToSend,
      // payloadToSend: { ...state.payloadToSend, created_at: new Date().toISOString() }, // would be use in further
      channel: channels,
      localId: dateId,
      ...(params.postCallPermissionRequest && {
        postCallPermissionRequest: params.postCallPermissionRequest,
      }),
    }
    await dispatch('sendToAPI', payload)
  },
  async sendToAPI({ commit, state, dispatch }, params) {
    // Do request
    const index = await _.findIndex(state.conversations, { id: params.localId })
    const url =
      params.type !== 'comment'
        ? `${SEND_MESSAGE_URL}/${params.channel}`
        : `${SEND_MESSAGE_URL}/instagram_comment`
    const payloadToSend = params.payloadToSend
    requests.whatsapp
      .postService(url, payloadToSend, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        async (res) => {
          // Update current conversation ID and status based on response
          const data = {
            id: res.data.id,
            status: 'sent',
            type: params.type,
            index,
            reply: res.data.reply || params.reply,
            file: res.data.file,
            extra: res.data.extra,
            sender_id: res.data.sender_id,
          }

          // Update room if it's agent, because FCM is not correctly yet
          if (this.$auth.user.role === 'agent') {
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                participant_type: 'agent',
                customMsg: {
                  type: params.type,
                  text: params.text,
                },
                unreadUpdate: 0,
              },
              { root: true }
            )
          }

          // Update to current conversation
          await commit('UPDATE_CONVERSATION_STATUS', data)
          await commit('inbox/READ_ROOM', res.data.room_id, { root: true })
          await dispatch('inbox/liftUpRoomById', res.data.room_id, {
            root: true,
          })

          // this request would be generate cta bubble for wa call (call permission request feature)
          params &&
            params.postCallPermissionRequest &&
            params.postCallPermissionRequest()
        },
        async (err) => {
          const messages = err
          if (messages === 'Network Error') {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Unable to access when offline', variant: 'error' },
              { root: true }
            )
          } else if (params.channel === 'ig_comment') {
            await commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: err.error.messages[0], variant: 'error' },
              { root: true }
            )
          } else {
            await commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              {
                title: 'Failed to send message. Please try again later',
                variant: 'error',
              },
              { root: true }
            )
          }
          const index = await _.findIndex(state.conversations, {
            id: params.localId,
          })
          const data = await {
            id: params.localId,
            status: 'failed',
            index,
            err,
          }

          // Update to current conversation
          await commit('UPDATE_CONVERSATION_STATUS', data)
          await commit('inbox/READ_ROOM', state.query.room, { root: true })
        }
      )
  },
  async sendTokpedProductMessage({ commit, dispatch, state }, params) {
    const createdAt = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSS[Z]')
    await commit(`${params.type.toUpperCase()}_FORMATTER`, params)

    const dateId = params.type + createdAt
    await commit(`UPDATE_${params.type.toUpperCase()}_CONVERSATION`, {
      ...state.formattedMessage,
      local: true,
      buttons: [],
      id: dateId,
    })
    const channels = state.conversationInformation.channel.toLowerCase()
    const payload = {
      ...state.formattedMessage,
      payloadToSend: state.payloadToSend,
      // payloadToSend: { ...state.payloadToSend, created_at: new Date().toISOString() }, // would be use in further
      channel: channels,
      localId: dateId,
    }

    const sendToApi = async (params) => {
      // Do request
      const index = await _.findIndex(state.conversations, {
        id: params.localId,
      })
      const url = `${SEND_MESSAGE_URL}/${params.channel}`
      const payloadToSend = params.payloadToSend
      requests.whatsapp
        .postService(url, payloadToSend, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            // Update current conversation ID and status based on response
            let data = {}
            if (params.channel === 'tokopedia_chat') {
              data = {
                id: res.data[0]?.id || res.data,
                status: 'sent',
                type: params.type,
                index,
              }
            } else if (params.channel === 'shopee') {
              data = {
                id: res.data?.id || res.data,
                status: 'sent',
                type: params.type,
                index,
              }
            } else {
              data = {
                id: res.data?.id || res.data,
                status: 'sent',
                type: params.type,
                index,
              }
            }

            // Update room if it's agent, because FCM is not correctly yet
            if (this.$auth.user.role === 'agent') {
              await commit(
                'inbox/UPDATE_ROOM',
                {
                  conversationInformation: state.conversationInformation,
                  sender_type: 'message',
                  participant_type: 'agent',
                  customMsg: {
                    type: params.type,
                    text: params.text,
                  },
                  unreadUpdate: 0,
                },
                { root: true }
              )
            }
            // Update to current conversation
            await commit('UPDATE_CONVERSATION_STATUS', data)
            await commit('inbox/READ_ROOM', res.data.room_id, { root: true })
            await dispatch('inbox/liftUpRoomById', res.data.room_id, {
              root: true,
            })
            await commit(
              'tokopedia/CLEAR_SELECTED_PRODUCTS',
              {},
              { root: true }
            )
            await commit(
              'shopeeInbox/CLEAR_SELECTED_PRODUCTS',
              {},
              { root: true }
            )
          },
          async (err) => {
            const messages = err
            if (messages === 'Network Error') {
              commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title: 'Unable to access when offline', variant: 'error' },
                { root: true }
              )
            } else if (params.channel === 'ig_comment') {
              await commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title: err.error.messages[0], variant: 'error' },
                { root: true }
              )
            } else {
              await commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                {
                  title: 'Failed to send message. Please try again later',
                  variant: 'error',
                },
                { root: true }
              )
            }
            const index = await _.findIndex(state.conversations, {
              id: params.localId,
            })
            const data = await {
              id: params.localId,
              status: 'failed',
              index,
              err,
            }
            // Update to current conversation
            await commit('UPDATE_CONVERSATION_STATUS', data)
            await commit('inbox/READ_ROOM', state.query.room, { root: true })
            await commit(
              'tokopedia/CLEAR_SELECTED_PRODUCTS',
              {},
              { root: true }
            )
            await commit('shopee/CLEAR_SELECTED_PRODUCTS', {}, { root: true })
          }
        )
    }
    sendToApi(payload)
  },
  async sendTokpedInvoiceMessage({ commit, dispatch, state }, params) {
    const createdAt = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSS[Z]')
    await commit(`${params.type.toUpperCase()}_FORMATTER`, params)

    const dateId = params.type + createdAt
    await commit(`UPDATE_${params.type.toUpperCase()}_CONVERSATION`, {
      ...state.formattedMessage,
      local: true,
      buttons: [],
      id: dateId,
    })
    const channels = state.conversationInformation.channel.toLowerCase()
    const payload = {
      ...state.formattedMessage,
      payloadToSend: state.payloadToSend,
      // payloadToSend: { ...state.payloadToSend, created_at: new Date().toISOString() }, // would be use in further
      channel: channels,
      localId: dateId,
    }

    const sendToApi = async (params) => {
      // Do request
      const index = await _.findIndex(state.conversations, {
        id: params.localId,
      })
      const url = `${SEND_MESSAGE_URL}/${params.channel}`
      const payloadToSend = params.payloadToSend
      requests.whatsapp
        .postService(url, payloadToSend, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            // Update current conversation ID and status based on response
            const data = {
              id: res.data.id,
              status: 'sent',
              type: params.type,
              index,
            }

            // Update room if it's agent, because FCM is not correctly yet
            if (this.$auth.user.role === 'agent') {
              await commit(
                'inbox/UPDATE_ROOM',
                {
                  conversationInformation: state.conversationInformation,
                  sender_type: 'message',
                  participant_type: 'agent',
                  customMsg: {
                    type: params.type,
                    text: params.text,
                  },
                  unreadUpdate: 0,
                },
                { root: true }
              )
            }
            // Update to current conversation
            await commit('UPDATE_CONVERSATION_STATUS', data)
            await commit('inbox/READ_ROOM', res.data.room_id, { root: true })
            await dispatch('inbox/liftUpRoomById', res.data.room_id, {
              root: true,
            })
            await commit('tokopedia/CLEAR_SELECTED_ORDERS', {}, { root: true })
          },
          async (err) => {
            const messages = err
            if (messages === 'Network Error') {
              commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title: 'Unable to access when offline', variant: 'error' },
                { root: true }
              )
            } else if (params.channel === 'ig_comment') {
              await commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title: err.error.messages[0], variant: 'error' },
                { root: true }
              )
            } else {
              await commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                {
                  title: 'Failed to send message. Please try again later',
                  variant: 'error',
                },
                { root: true }
              )
            }
            const index = await _.findIndex(state.conversations, {
              id: params.localId,
            })
            const data = await {
              id: params.localId,
              status: 'failed',
              index,
              err,
            }
            // Update to current conversation
            await commit('UPDATE_CONVERSATION_STATUS', data)
            await commit('inbox/READ_ROOM', state.query.room, { root: true })
            await commit('tokopedia/CLEAR_SELECTED_ORDERS', {}, { root: true })
          }
        )
    }
    sendToApi(payload)
  },
  assignAgent({ commit, state, dispatch }, params) {
    const data = {
      id: params.room.id,
      user_id: params.agent.id,
    }
    let url
    let messageResp

    if (params.sender.role !== 'agent') {
      url = `${ROOMS_URL}/${data.id}/agents/${data.user_id}`
      messageResp =
        '<b>' +
        params.agent.full_name +
        '</b> has been assigned to this conversation'
    } else {
      url = `${ROOMS_URL}/${data.id}/handover/${data.user_id}`
      messageResp =
        '<b>' +
        params.sender.name +
        '</b> handed over this conversation to ' +
        '<b>' +
        params.agent.full_name +
        '</b>'
    }

    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(url, data, { Authorization: this.$auth.getToken('hub') })
        .subscribe(
          async (res) => {
            // Create temporary ID
            params.id = 'AGENT_' + moment().format('DDMMYYYYHHmmSS')

            // UPDATE ROOM STATUS
            await commit('UPDATE_ROOM_STATUS', 'assigned')

            // Update to room
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                customMsg: { type: 'notification', text: messageResp },
                unreadUpdate: null,
              },
              { root: true }
            )

            // Update assigned agent to current conversation information
            await commit('ASSIGN_AGENT', res.data)

            // if (res.data.room_id === state.query.room) {
            //   // Update notification to conversation
            //   await commit('UPDATE_ASSIGNED_AGENT_NOTIFICATION', params)
            // }

            // Update to designated room on room panel
            await commit(
              'inbox/UPDATE_ASSIGNATION_ROOM',
              {
                room: params.room,
                sender: params.sender,
              },
              { root: true }
            )

            // Remove room when agent = 1
            if (state.conversationInformation.agents.length === 1)
              commit(
                'inbox/REMOVE_ROOM',
                { id: state.conversationInformation.id },
                { root: true }
              )

            resolve(res)
          },
          (err) => {
            reject(err.error.messages[0])
          }
        )
    })
  },
  removeAgent({ commit, state, dispatch }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .deleteService(
          `${ROOMS_URL}/${params.room.id}/agents/${params.agent.contact_able_id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          async (res) => {
            // Create temporary ID
            params.id = 'AGENT_' + moment().format('DDMMYYYYHHmmSS')

            // UPDATE ROOM STATUS
            let status = ''
            if (state.conversationInformation.agents)
              status = state.conversationInformation.status
            await commit('UPDATE_ROOM_STATUS', status)

            // Update to room
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                customMsg: {
                  type: 'notification',
                  text:
                    '<b>' +
                    params.agent.profile.name +
                    '</b> has been removed from this conversation by <b>' +
                    params.sender.name +
                    '</b>',
                },
                unreadUpdate: null,
              },
              { root: true }
            )

            // Update assigned agent to current conversation information
            await commit('REMOVE_AGENT', params.agent)

            // Update notification to conversation
            // if (res.data.room_id === state.query.room) await commit('REMOVE_ASSIGNED_AGENT_NOTIFICATION', params)

            // Update to designated room on room panel
            await commit(
              'inbox/UPDATE_ASSIGNATION_ROOM',
              {
                room: params.room,
                sender: params.sender,
              },
              { root: true }
            )

            // Update if room agent is empty
            if (state.conversationInformation.agents.length < 1) {
              await commit(
                'inbox/UPDATE_ROOM_STATUS_AFTER_AGENT_ACTION',
                {
                  id: state.conversationInformation.id,
                  status: 'unassigned',
                },
                { root: true }
              )
              // Remove from room list because it is no longer "assigned"
              commit(
                'inbox/REMOVE_ROOM_EXCEPT_ON_SAME_FILTER',
                { id: state.conversationInformation.id, filter: 'unassigned' },
                { root: true }
              )

              dispatch(
                'inbox/getRoomSpecificInfo',
                { target: 'unassigned' },
                { root: true }
              )
            }

            // Display notification
            const toastMsg =
              params.agent.profile.name +
              ' has been removed from this conversation'
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: toastMsg },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              {
                title: err.error.messages[0],
                variant: 'error',
                position: 'bottom',
              },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  resolveConversation({ commit, state, dispatch }, params) {
    let paramsQuery = {
      is_sent_survey: params.isSentSurvey,
    }
    if (!params?.isManualSurvey) paramsQuery = {}
    if (!params?.isNewSurveyFlow) paramsQuery = {}

    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${ROOMS_URL}/${params.room.id}/resolve`, paramsQuery, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            // Create temporary ID
            params.id = 'RESOLVE_' + moment().format('DDMMYYYYHHmmSS')

            // UPDATE ROOM STATUS
            await commit('UPDATE_ROOM_STATUS', 'resolved')

            // Update to room
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                customMsg: {
                  type: 'notification',
                  text:
                    'Conversation has been resolved by ' +
                    params.agent.full_name,
                },
                unreadUpdate: null,
              },
              { root: true }
            )

            // Set conversation status resolve to true
            await commit('RESOLVE_CONV', true)

            // Update notification to conversation
            // if (res.data.room_id === state.query.room) commit('UPDATE_RESOLVED_NOTIFICATION', params)

            // Remove from room list because it is no longer "resolved"
            commit(
              'inbox/REMOVE_ROOM_EXCEPT_ON_SAME_FILTER',
              {
                id: state.conversationInformation.id,
                filter: 'resolve',
              },
              { root: true }
            )

            resolve(true)
          },
          (err) => {
            reject(err.error.messages[0])
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: err?.error?.messages?.[0], variant: 'error' },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  editConversationInformationNotes({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(
          `${ROOMS_NOTES}/${params.id}`,
          { text: params.text },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('EDIT_CONVERSATION_INFORMATION_NOTES', res.data)
            commit('UPDATE_EDIT_MODE', {
              type: params.type,
              value: !state.editMode[params.type],
            })
            resolve(true)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  editConversationInformationName({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .putService(
          `${ROOMS_URL}/${params.id}`,
          { name: params.text },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('inbox/UPDATE_ROOM_NAME_ON_ROOM_LIST', params, {
              root: true,
            })
            commit('UPDATE_EDIT_MODE', {
              type: params.type,
              value: !state.editMode[params.type],
            })
            commit('UPDATE_CONVERSATION_INFORMATION_BY_FIELD', {
              field: 'name',
              data: params.text,
            })
            resolve(true)
          },
          () => {
            resolve(false)
          }
        )
    })
  },
  getAgentAssignLists({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params.room_id}/agents/assignable`,
          params.payload,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_AGENT_LISTS', res.data)
            commit('layouts/SET_META', res.meta, { root: true })
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getInfiniteAgentAssignLists({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params.room_id}/agents/assignable`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_AGENT_LISTS', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err?.error?.messages[0] || '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getInfiniteAgentAssignListsByPage({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params.room_id}/agents/assignable`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('PUSH_AGENT_LISTS', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getConversationPure({ commit, state, dispatch }, params) {
    const conversations = state.conversations
    const currentConv = conversations.length
      ? conversations[conversations.length - 1]
      : {}
    const cursor = currentConv.created_at
      ? moment(currentConv.created_at).valueOf() - params.convPoolingThreshold
      : ''

    if (!cursor) {
      return
    }

    const parameter = {
      limit: params.limit,
      offset: params.offset,
      cursor,
      cursor_direction: 'after',
    }
    const url = `${ROOMS_MESSAGE}/${params.room}`
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(url, parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (response) => {
            const latestConvSlice = await compareConversations({
              conversations,
              currentConv,
              data: response.data,
              room: params.room,
            })

            // refresh room detail for wa call needs
            const isMessageResponseCallRequestPermission =
              response.data.findLast((conv) => {
                return conv.reply
                  ? conv.reply.type === 'call_permission_request' || false
                  : false
              })
            const isMessageCallRequestPermission = response.data.findLast(
              (conv) => {
                return conv.type === 'call_permission_request'
              }
            )
            const isRoomIdExist = response.data[0].room_id || false

            if (
              (isMessageResponseCallRequestPermission ||
                isMessageCallRequestPermission) &&
              isRoomIdExist
            ) {
              dispatch('inbox/getRoomDetail', isRoomIdExist, {
                root: true,
              })
            }

            if (latestConvSlice) {
              commit('REMOVE_CONVERSATION', latestConvSlice)
              commit('CONVERSATION_FORMATTER', {
                conversations: state.conversationsFormatted,
                isLoadMore: params.loadMoreTrigger,
              })
              commit('UPDATE_CONVERSATION', {
                conversations: state.conversationsFormatted,
                isLoadMore: params.loadMoreTrigger,
              })
              resolve(true)
            } else {
              resolve(false)
            }
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getConversation({ commit, state }, params) {
    const url = params.is_ig_comment
      ? `${IG_COMMENT_ROOMS_MESSAGE}/${params.room}`
      : `${ROOMS_MESSAGE}/${
          params.nextRoomId ? params.nextRoomId : params.room
        }`
    const parameter = {
      limit: params.limit,
      offset: params.offset,
      cursor: params.cursor,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(url, parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const queryParams = new URLSearchParams(window.location.search)
            const currentRoom = queryParams.get('room')
            if (currentRoom !== params.room) {
              return
            }
            const roomId = res.data.length > 0 ? res?.data?.[0]?.room_id : null
            const raceConditionStatement = params.loadMoreTrigger
              ? true
              : roomId === state.query.room

            if (state.query.room) {
              if (res.data.length > 0 && raceConditionStatement) {
                commit('REMOVE_CONVERSATION', res.data)
                commit('CONVERSATION_FORMATTER', {
                  conversations: state.conversationsFormatted,
                  isLoadMore: params.loadMoreTrigger,
                })
                commit('UPDATE_CONVERSATION', {
                  conversations: state.conversationsFormatted,
                  isLoadMore: params.loadMoreTrigger,
                })
              }
              commit('SET_META', res.meta)
            } else {
              commit('REMOVE_CONVERSATION', res.data)
              commit('CONVERSATION_FORMATTER', {
                conversations: state.conversationsFormatted,
                isLoadMore: params.loadMoreTrigger,
              })
              commit('UPDATE_CONVERSATION', {
                conversations: state.conversationsFormatted,
                isLoadMore: params.loadMoreTrigger,
              })
              commit('SET_META', res.meta)
            }
            resolve(res.data.length)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err?.error?.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('conversation/UPDATE_QUERY_ROOM', '', { root: true })
            reject(err)
          }
        )
    })
  },
  async getConversationInformation({ commit, state, dispatch }, params) {
    if (state.conversations.length === 0) {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'content2', status: true },
        { root: true }
      )
    }
    await commit('REMOVE_CONV_INFORMATION')
    return new Promise((resolve, reject) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'content3', status: true },
        { root: true }
      )
      commit('UPDATE_EDIT_MODE', { type: 'email', value: false })
      commit('UPDATE_EDIT_MODE', { type: 'notes', value: false })
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          async (res) => {
            if (res.data.id === this.app.router.currentRoute.query.room) {
              await commit('UPDATE_CONVERSATION_INFORMATION', res.data)
              await commit('UPDATE_CHAT_ROOM_DETAILS', res.data)
              await commit('inbox/UPDATE_ROOM_DETAIL', res.data, {
                root: true,
              })
              await commit('inbox/UPDATE_CONV_MODE', res.data.channel, {
                root: true,
              })
            }
            commit('EMPTY_TAG', [])
            for (const x in res.data.tags) {
              dispatch(
                'chat/recoverTag',
                { tag: res.data.tags[x] },
                { root: true }
              )
            }
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content2', status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content3', status: false },
              { root: true }
            )
            if (res.data.session === 'expired')
              dispatch(
                'inbox/removeCurrentRoomFromRDB',
                { room_id: state.conversationInformation.id },
                { root: true }
              )

            if (state.enable_sla_inbox) {
              let roomId = params

              if (res.data.id) {
                roomId = res.data.id
              } else if (this.app.router.currentRoute.query.room) {
                roomId = this.app.router.currentRoute.query.room
              } else {
                roomId = state.conversationInformation.id
              }

              dispatch('getInboxSLAByActor', { room_id: roomId })
            }

            if (res.data.channel === 'google_my_business') {
              dispatch('getGBRLocationDetails', res.data)
            }
            dispatch('getContactInformation', params)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content2', status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content3', status: false },
              { root: true }
            )
            commit('conversation/UPDATE_QUERY_ROOM', '', { root: true })
            reject(err)
          }
        )
    })
  },
  getContactInformation({ state, commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${CONTACTS_URL}/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          async (res) => {
            await commit('UPDATE_CONVERSATION_CONTACT_INFORMATION', res.data)
            await commit('UPDATE_CALL_PERMISSION_REQUEST', {
              channelIntegrationId:
                state.conversationInformation.channelIntegrationId,
              childs: res.data?.childs ?? [],
              contactId: res.data?.contactId ?? res.data?.id ?? '',
            })
            await dispatch('getContactObjectById', res.data)
            resolve(res)
          },
          (err) => {
            commit('UPDATE_CONVERSATION_CONTACT_INFORMATION', {
              email: 404,
              phone_number: 404,
            })
            reject(err)
          }
        )
    })
  },
  getContactObjectById({ commit, state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${CONTACTS_OBJECT}/${params.id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          async (res) => {
            await commit('SET_CONTACT_DETAIL_DATA', res.data)
            await dispatch('getContactFields', { contact_id: params.id })
            await dispatch('getActiveRoom', res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
        )
    })
  },
  async getCustomFields({ commit, state, dispatch }, params) {
    await requests.whatsapp
      .getService(`${CONTACTS_OBJECT}/contact_field`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        async (res) => {
          // await commit('SET_CONTACT_DETAIL_DATA_CUSTOM_FIELD', res.data)
        },
        async (err) => {
          await commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  async getContactFields({ commit, state }, params) {
    // get custom field contact object detail page
    await requests.whatsapp
      .getService(`${CONTACTS_OBJECT}/contact_field`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        async (res) => {
          await commit('SET_CONTACT_DETAIL_DATA_CUSTOM_FIELD', res.data)
        },
        async (err) => {
          await commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getActiveRoom({ commit, state, dispatch }, params) {
    const datas = []
    const parent = {
      id: params.id,
      account_uniq_id: params.account_uniq_id,
    }
    datas.push(parent)

    const childs = params.childs
    for (let i = 0; i < childs.length; i++) {
      const el = childs[i]
      const child = {
        id: el.id,
        account_uniq_id: el.account_uniq_id,
      }
      datas.push(child)
    }
    const activeRoomData = []
    const sendToApi = (el) =>
      new Promise((resolve, reject) => {
        requests.whatsapp
          .getService(
            `${CONTACTS_OBJECT}/${el.id}/active_room`,
            el.account_uniq_id,
            { Authorization: this.$auth.getToken('hub') }
          )
          .subscribe(
            (res) => {
              resolve(res)
            },
            (err) => {
              reject(err)
            }
          )
      })

    const getAllRooms = async () => {
      const promises = datas.map((el) => sendToApi(el))
      const results = await Promise.allSettled(promises)
      results.forEach((res) => {
        const noRoom = {
          id: null,
          status: 'resolved',
          session: 'expired',
        }
        if (res.status === 'fulfilled') {
          if (res?.value?.data?.response?.length) {
            res.value.data.response[0].session = ''
            const response = res.value.data.response[0]
            activeRoomData.push(response)
          } else {
            activeRoomData.push(noRoom)
          }
        } else if (res.status === 'rejected') {
          activeRoomData.push(noRoom)
        }
      })
      commit('SET_ACTIVE_ROOM_DATA', activeRoomData)
    }
    getAllRooms()
  },
  updateTags({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .postService(
          `${ROOMS_URL}/${params.id}/tags`,
          { tag: params.tag },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Successfully updated room tags' },
              { root: true }
            )
            commit(
              'inbox/UPDATE_ROOM_TAGS',
              { id: state.conversationInformation.id, tag: params.tag },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            const index = _.findIndex(state.conversationInformation.tags, {
              name: params.tag,
            })
            commit('REMOVE_TAG', index)
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Failed to update room tags' },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  updateUnreadCountRoom({ commit, state }, params) {
    commit('UPDATING_READ_ALL')
    requests.whatsapp
      .putService(`${ROOMS_URL}/${params.id}/mark_all_as_read`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe((res) => {
        commit('UPDATE_UNREAD_IN_ROOM', 0)
        commit('inbox/READ_ROOM', params.id, { root: true })
        commit('UPDATED_READ_ALL')
      })
  },
  removeTags({ commit, state, dispatch }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .deleteService(
          `${ROOMS_URL}/${params.id}/tags`,
          { tag: params.tag },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Successfully updated room tags' },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            commit(
              'inbox/REMOVE_ROOM_TAGS',
              { id: params.id, tag: params.tag },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            dispatch('chat/recoverTag', { tag: params.tag }, { root: true })
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Failed to update room tags' },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  getParticipantLists({ commit, state }, params) {
    const query = {
      type: ['agent', 'customer', 'system'],
      active_status: ['active', 'kicked'],
    }

    if (!params.id) {
      throw new Error('"getParticipantLists" Room ID is required')
    }

    requests.whatsapp
      .getService(`${ROOMS_URL_V1_1}/${params.id}/participants`, query, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe((res) => {
        // update all participants
        commit('UPDATE_ALL_ROOM_PARTICIPANTS', res.data)

        // update agent list
        const agents = res.data.filter(
          (data) =>
            data.type === 'Models::AgentParticipant' &&
            data.active_status === 'active'
        )
        commit('UPDATE_PARTICIPANT_LIST', agents)

        if (state.query.room) {
          const index = _.findIndex(res.data, {
            contact_able_id: this.$auth.user.id,
          })
          if (index > -1) {
            commit(
              'layouts/UPDATE_NOTIFICATION_READ',
              {
                type: 'inbox',
                room: {
                  id: params.id,
                },
              },
              { root: true }
            )
          }
        }
      })
  },
  getAgentLists({ commit, state }, params) {
    requests.whatsapp
      .getService(
        `${ROOMS_URL}/${params.id}/participants`,
        { type: 'agent' },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_PARTICIPANT_LIST', res.data)
          const isParticipant = _.findIndex(
            state.conversationInformation.agents,
            { contact_able_id: this.$auth.user.id }
          )
          if (isParticipant > -1) {
            commit(
              'layouts/UPDATE_NOTIFICATION_READ',
              {
                type: params.selected_notification_menu,
                room: {
                  id: params.id,
                },
              },
              { root: true }
            )
          }
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getRoomHistories({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${ROOMS_URL}/${params.id}/histories`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_META', res.meta)
            commit('UPDATE_ROOM_HISTORY', res.data)
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  createNewRoomTypingOnRDB({ commit, state }, params) {
    this.$fireDb
      .ref(`room/${this.$auth.user.organization_id}/${state.query.room}`)
      .set(params)
  },
  sendHsmTemplate({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      let channels = state.conversationInformation.channel.toLowerCase()
      if (channels === 'wa_cloud' || channels === 'wa') channels = 'whatsapp'
      requests.whatsapp
        .postService(`${SEND_MESSAGE_URL}/${channels}/hsm`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          () => {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Message sent' },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            const errorMessage = err.error.messages[0]
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: errorMessage, variant: 'error' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  updateContactAccount({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    commit('UPDATE_CONVERSATION_INFORMATION_CONTACT_ACCOUNT', {
      name: params.name,
      email: params.email,
      phone: params.phone.code.code + params.phone.number,
    })
    commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
    commit(
      'layouts/UPDATE_NOTIFICATION',
      {
        display: true,
        type: 'success',
        message: 'Successfully Updated!',
        item: '',
        callback: {
          text: 'OK',
          method: 'closeNotification',
        },
      },
      { root: true }
    )
  },
  mergeContactObject({ commit, dispatch }, params) {
    // kemungkinan tidak dipakai lagi
    const payloadCheckContactObjId = {
      'account_uniq_ids[]': params.contactUniqIds[0],
    }
    // Get Contact Object Id
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(CONTACTS_OBJECT, payloadCheckContactObjId, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            const payloadCheck = {
              channel: params.channel,
              reference_contact_id: res.data[0].id,
              channel_integration_id: params.channelIntegrationId,
              query: params.query,
            }
            // Check Contact Valid / Not
            await requests.whatsapp
              .getService(CONTACTS_HANDLERS, payloadCheck, {
                Authorization: this.$auth.getToken('hub'),
              })
              .subscribe(
                async (res) => {
                  if (res.data.length > 0) {
                    // Merge Contact
                    const formData = new FormData()
                    formData.append('contact_handler_id', params.handlerId)
                    formData.append(
                      'account_uniq_id',
                      res.data[0].account_uniq_id
                    )
                    formData.append(
                      'channel_integration_id',
                      params.channelIntegrationId
                    )
                    formData.append('channel', params.channel)

                    if (res.data[0].contact_handler_id === null) {
                      await requests.whatsapp
                        .postService(`${CONTACTS_OBJECT}/merge`, formData, {
                          Authorization: this.$auth.getToken('hub'),
                        })
                        .subscribe((res) => {
                          commit(
                            'layouts/DISPLAY_TOAST',
                            { message: 'Success Add Handle' },
                            { root: true }
                          )
                          resolve(true)
                        })
                    } else {
                      const payloadMergeUnmerge = {
                        contact_id: res.data[0].id,
                        unmerge_handler_id: res.data[0].contact_handler_id,
                        merge_handler_id: params.handlerId,
                      }
                      if (
                        payloadMergeUnmerge.unmerge_handler_id ===
                        payloadMergeUnmerge.merge_handler_id
                      ) {
                        commit(
                          'layouts/UPDATE_NOTIFICATION',
                          {
                            display: true,
                            type: 'failed',
                            message: '',
                            item: 'Contact already in this handler',
                            callback: {
                              text: 'OK',
                              method: 'closeNotification',
                            },
                          },
                          { root: true }
                        )
                      } else {
                        await dispatch(
                          'mergeContactHandlers',
                          payloadMergeUnmerge
                        )
                      }
                    }
                  } else {
                    commit(
                      'layouts/UPDATE_NOTIFICATION',
                      {
                        display: true,
                        type: 'failed',
                        message: '',
                        item: 'Contact Not Found!',
                        callback: {
                          text: 'OK',
                          method: 'closeNotification',
                        },
                      },
                      { root: true }
                    )
                  }
                },
                (err) => {
                  commit(
                    'layouts/UPDATE_NOTIFICATION',
                    {
                      display: true,
                      type: 'failed',
                      message: '',
                      item: err.error.messages[0],
                      callback: {
                        text: 'OK',
                        method: 'closeNotification',
                      },
                    },
                    { root: true }
                  )
                }
              )
          },
          // Error Get Contact Object Id
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: '',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
        )
    })
  },
  mergeContactHandlers({ commit, state, dispatch }, params) {
    const payloadPost = {
      contact_id: params.contact_id,
      unmerge_handler_id: params.unmerge_handler_id,
      merge_handler_id: params.merge_handler_id,
    }
    commit('layouts/UPDATE_MODAL_ADD_HANDLERS', payloadPost, { root: true })
  },
  ActionMergeHandlers({ commit, state }, params) {
    const payloadUnmerge = {
      handler_contact_id: params.unmerge_handler_id,
      target_contact_id: params.contact_id,
    }
    const payloadMerge = {
      handler_contact_id: params.merge_handler_id,
      target_contact_id: params.contact_id,
    }
    // Unmerge handler firsts
    return new Promise((resolve) => {
      requests.whatsapp
        .deleteService(`${CONTACTS_HANDLERS}/demerge`, payloadUnmerge, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            // commit('layouts/DISPLAY_TOAST', { message: 'Success delete handlers' }, { root: true })
            requests.whatsapp
              .postService(`${CONTACTS_HANDLERS}/merge`, payloadMerge, {
                Authorization: this.$auth.getToken('hub'),
              })
              .subscribe(
                async () => {
                  await commit('layouts/UPDATE_CONTACT_OBJECT_MODAL', false, {
                    root: true,
                  })
                  await commit(
                    'layouts/DISPLAY_TOAST',
                    { message: 'Success Add handlers' },
                    { root: true }
                  )
                  resolve(true)
                },
                (err) => {
                  commit(
                    'layouts/UPDATE_NOTIFICATION',
                    {
                      display: true,
                      type: 'failed',
                      message: 'Error!',
                      item: err.error.messages[0],
                      callback: {
                        text: 'OK',
                        method: 'closeNotification',
                      },
                    },
                    { root: true }
                  )
                  commit(
                    'layouts/UPDATE_CONTENTLOADING',
                    { type: 'content', status: false },
                    { root: true }
                  )
                  resolve(false)
                }
              )
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  removeHandlers({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .deleteService(`${CONTACTS_HANDLERS}/demerge`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Success delete handlers' },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  uploadMedia({ commit, state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      const myHeaders = new Headers()
      myHeaders.append('Authorization', this.$auth.getToken('hub'))

      const formdata = new FormData()
      formdata.append('folder', params.file_type)
      formdata.append('file', params.file)

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      }

      fetch(UPLOAD_URL, requestOptions)
        .then((data) => data.json())
        .then((res) => {
          params.imageUrl = res.data.url
          resolve(params)
        })
        .catch(() => {
          reject(params)
        })
    })
  },
  getRoomTicket({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${TICKET_URL}/rooms/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            if (res.data && Object.keys(res.data).length > 0) {
              commit('SET_ROOM_TICKET', res.data)
              resolve(res.data)
            } else {
              commit('SET_ROOM_TICKET', null)
              resolve(null)
            }
          },
          (err) => {
            commit('SET_ROOM_TICKET', null)
            reject(err)
          }
        )
    })
  },
  addRoomTicket({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${TICKET_URL}/rooms`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_ROOM_TICKET', res.data)
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Submitted the Ticket!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )

            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )

            reject(err)
          }
        )
    })
  },
  uploadMessageFile({ commit }, params) {
    return new Promise((resolve, reject) => {
      const formdata = new FormData()
      formdata.append('file', params.file)
      requests.whatsapp
        .postService(`${UPLOAD_URL}/message`, formdata, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error Upload File!',
                item: err?.error?.messages[0] ?? err,
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )

            reject(err)
          }
        )
    })
  },
  getMessageById({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${ROOMS_MESSAGE}/${params.room_id}/${params.id}`, null, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  blockContact({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const param = {
        room_id: params.roomId,
        reaspon: params.reason,
      }
      requests.whatsapp
        .contactBlock(`${CONTACT_BLOCK}`, param, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            await commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Successfully blocked contact' },
              { root: true }
            )
            await commit(
              'UPDATE_CONVERSATION_INFORMATION_CONTACT_BLOCKED',
              true
            )
            await commit(
              'inbox/UPDATE_IS_CONTACT_BLOCKED_ON_ROOM_LIST',
              { roomId: params.roomId, isBlocked: true, status: 'unassigned' },
              { root: true }
            )
            await commit('REMOVE_ALL_AGENT')
            // Update to room
            // await commit(
            //   'inbox/UPDATE_ROOM',
            //   {
            //     conversationInformation: state.conversationInformation,
            //     sender_type: 'message',
            //     customMsg: {
            //       type: 'notification',
            //       text: '<b>' + params.agent.full_name + '</b> blocked this contact'
            //     },
            //     unreadUpdate: null
            //   },
            //   { root: true }
            // )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              {
                title: 'Block Contact Error, try again later',
                variant: 'error',
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  unblockContact({ commit, state }, params) {
    const payload = {
      room_id: params.roomId,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .unblockContact(`${CONTACT_BLOCK}/${params.room_id}`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          async (res) => {
            await commit(
              'UPDATE_CONVERSATION_INFORMATION_CONTACT_BLOCKED',
              false
            )
            await commit(
              'inbox/UPDATE_IS_CONTACT_BLOCKED_ON_ROOM_LIST',
              { roomId: params.roomId, isBlocked: false, status: 'unassigned' },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  reopenConversation({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${ROOMS_URL}/${params.id}/reopen`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_REOPEN_ROOM', res.data)
            commit('RE_RENDER')
            commit('inbox/UPDATE_REOPEN_ROOM_LIST', res.data, { root: true })
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: 'Room is opened' },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit('RE_RENDER')
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              { title: err.error.messages[0], variant: 'error' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  postTheReply({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${SEND_MESSAGE_URL}/instagram_comment`, params.params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            if (err === 'Network Error') {
              commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                {
                  title: 'Unable to access when offline',
                  variant: 'error',
                },
                { root: true }
              )
            } else {
              commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title: err, variant: 'error' },
                { root: true }
              )
            }
            reject(err)
          }
        )
    })
  },
  loadMoreReplies({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const payload = {
        room_id: params.room_id,
        reply_id: params.reply_id,
        offset: params.offset,
        limit: params.limit,
        order_direction: params.order_direction,
      }
      requests.whatsapp
        .getService(`${IG_COMMENT_ROOMS_MESSAGE}/${params.room_id}`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            // commit('PUSH_MORE_REPLIES_COMMENTS', { data: res, reply_id: params.reply_id })
            commit('PUSH_LOAD_MORE_REPLIES_COMMENT', { data: res, params })
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getMessagesById({ commit, state }, params) {
    const payload = {
      refetch_media: params?.refetch_media || false,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_MESSAGE}/${params.roomId}/${params.msgId}`,
          payload,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_MESSAGE_BY_ID', res.data)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getInboxSLAByActor({ commit, state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${REPORTS_URL}/inbox-sla/by-actor`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_ROOM_SLA', res.data)
            dispatch('getSLAParticipant', {
              room_id: params.room_id,
              parameter: {
                type: ['agent'],
                active_status: ['active', 'kicked'],
              },
              participant_id: res.data.participant_id,
            })
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getSLAParticipant({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params.room_id}/get_participant_sla`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            // check the latest activity of agent in SLA
            const agentData = res.data.filter((participant) => {
              return participant.id === params.participant_id
            })

            // check if user is participant
            const participantCheck = res.data.filter((data) => {
              return this.$auth.user.id === data.contact_able_id
            })

            // if SLA user data is exist
            if (this.$auth.user.role === 'agent') {
              commit('UPDATE_ROOM_SLA', {
                resolved_time_due: participantCheck[0].resolved_time_due,
                response_time_due: participantCheck[0].response_time_due,
                sla_created_at: participantCheck[0].created_at,
                sla_response_status: 'calculating',
                sla_resolved_status: 'calculating',
              })
            } else if (agentData.length > 0) {
              commit('UPDATE_ROOM_SLA', {
                resolved_time_due: agentData[0].resolved_time_due,
                response_time_due: agentData[0].response_time_due,
                sla_created_at: agentData[0].created_at,
                sla_response_status: 'calculating',
                sla_resolved_status: 'calculating',
              })
            }

            commit('UPDATE_ROOM_SLA', { has_sla_list: res.data.length > 0 })
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  editReviewMessage({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const messageIdx = _.findIndex(state.conversations, {
        id: state.replyMessage.id,
      })
      const editedId = messageIdx
        ? `${state.conversations[messageIdx].id}_edit`
        : state.conversations[messageIdx].id
      if (messageIdx >= 0) {
        commit('UPDATE_MESSAGE_STATUS', {
          index: messageIdx,
          status: 'pending',
        })
        commit('UPDATE_MESSAGE_ID', {
          index: messageIdx,
          id: editedId,
        })
      }
      commit('UPDATE_REPLY_MESSAGE', null)
      requests.whatsapp
        .postService(`${SEND_MESSAGE_URL}/google_my_business`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (messageIdx >= 0) {
              commit('UPDATE_MESSAGE_ID', {
                index: messageIdx,
                id: res.data.id,
              })
              commit('UPDATE_MESSAGE_STATUS', {
                index: messageIdx,
                status: 'sent',
              })
              commit('UPDATE_MESSAGE_TEXT', {
                index: messageIdx,
                text: res.data.text,
              })
            }
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getGBRLocationDetails({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      try {
        commit('UPDATE_GBR_LOCATION_DETAILS', { status: 'fetching' })
        requests.whatsapp
          .getService(
            `${ROOMS_URL}/${params.id}/google_my_business_location`,
            params,
            {
              Authorization: this.$auth.getToken('hub'),
            }
          )
          .subscribe(
            (res) => {
              commit('UPDATE_GBR_LOCATION_DETAILS', res.data)
              resolve(res)
            },
            (err) => {
              commit('UPDATE_GBR_LOCATION_DETAILS', { status: 'error' })
              reject(err)
            }
          )
      } catch (error) {
        commit('UPDATE_GBR_LOCATION_DETAILS', { status: 'error' })
      }
    })
  },
}

export const mutations = {
  updateField,
  TEXT_FORMATTER(state, payload) {
    state.formattedMessage = payload
    state.payloadToSend = payload
  },
  IMAGE_FORMATTER(state, payload) {
    state.formattedMessage = payload
    const formData = new FormData()
    formData.append('room_id', payload.room_id)
    formData.append('type', payload.type)
    if (payload.reply_id) formData.append('reply_id', payload.reply_id)
    if (
      state.conversationInformation.channel === 'twitter' ||
      state.conversationInformation.channel === 'email'
    ) {
      formData.append('file', payload.file)
    } else {
      formData.append('file_url', payload.file_url)
    }

    if (payload.text) formData.append('text', payload.text)
    state.payloadToSend = formData
  },
  VIDEO_FORMATTER(state, payload) {
    state.formattedMessage = payload
    const formData = new FormData()
    formData.append('room_id', payload.room_id)
    formData.append('type', payload.type)
    if (payload.reply_id) formData.append('reply_id', payload.reply_id)
    if (state.conversationInformation.channel === 'twitter') {
      formData.append('file', payload.file)
    } else {
      formData.append('file_url', payload.file_url)
    }

    if (payload.text) formData.append('text', payload.text)
    state.payloadToSend = formData
  },
  DOCUMENT_FORMATTER(state, payload) {
    state.formattedMessage = payload
    const formData = new FormData()
    formData.append('room_id', payload.room_id)
    formData.append('type', payload.type)
    if (payload.reply_id) formData.append('reply_id', payload.reply_id)
    if (
      state.conversationInformation.channel === 'twitter' ||
      state.conversationInformation.channel === 'email'
    ) {
      formData.append('file', payload.file)
    } else {
      formData.append('file_url', payload.file_url)
    }

    if (payload.text) formData.append('text', payload.text)
    state.payloadToSend = formData
  },
  AUDIO_FORMATTER(state, payload) {
    state.formattedMessage = payload
    const formData = new FormData()
    formData.append('room_id', payload.room_id)
    formData.append('type', payload.type)
    if (payload.reply_id) formData.append('reply_id', payload.reply_id)
    if (state.conversationInformation.channel === 'twitter') {
      formData.append('file', payload.file)
    } else {
      formData.append('file_url', payload.file_url)
    }

    if (payload.text) formData.append('text', payload.text)
    state.payloadToSend = formData
  },
  COMMENT_FORMATTER(state, payload) {
    state.formattedMessage = payload
    state.payloadToSend = payload
  },
  CONVERSATION_FORMATTER(state, payload) {
    const message = conversationFormatter(
      payload.conversations,
      state.conversationInformation.channel,
      state.conversationInformation.participants,
      payload.isLoadMore
    )
    state.conversationsFormatted = message
  },
  PRODUCT_FORMATTER(state, payload) {
    state.formattedMessage = payload
    state.payloadToSend = payload
  },
  INVOICE_FORMATTER(state, payload) {
    state.formattedMessage = payload
    state.payloadToSend = payload
  },
  ORDER_FORMATTER(state, payload) {
    state.formattedMessage = payload
    state.payloadToSend = payload
  },
  PUSH_CONVERSATION(state, payload) {
    const pushConversation = () => {
      const index = _.findLastIndex(state.conversations, { local: true })
      const indexTarget = state.conversations.findIndex(
        (conversation) => conversation.id === payload.id
      )
      const isFound = indexTarget >= 0
      const isCallNotif = payload.message && payload.message.type === 'calls'

      if (index >= 0) {
        // maybe used later
        // state.conversations.splice(index, 1)
        // state.conversations = [...state.conversations.slice(0, index), payload, ...state.conversations.slice(index)]
        state.indexOfConversationLength = false
      } else if (index < 0 && !isFound) {
        state.conversations.push(payload)
        state.indexOfConversationLength = true
      } else if (index < 0 && isFound && isCallNotif) {
        if (state.conversations[indexTarget].text === 'Declined') {
          return
        }
        state.conversations[indexTarget] = payload
      }
    }

    const pushConversationIgComment = () => {
      const index = _.findLastIndex(state.conversations, { local: true })
      if (index >= 0) {
        // maybe used later
        // state.conversations.splice(index, 1)
        // state.conversations = [...state.conversations.slice(0, index), payload, ...state.conversations.slice(index)]
        state.indexOfConversationLength = false
      } else if (
        index < 0 &&
        _.findIndex(state.conversations, { id: payload.id }) < 0
      ) {
        const newComment = payload
        newComment.childs = {
          data: [],
          meta: {},
        }
        state.conversations.push(newComment)
        state.indexOfConversationLength = true
      }
    }

    const pushIgComment = () => {
      // check. is child comment or not.
      if (payload.reply && typeof payload.reply === 'object') {
        const parentIdx = _.findIndex(state.conversations, function (o) {
          return o.id === payload.reply.id
        })
        const childIdx = _.findIndex(
          state.conversations[parentIdx].childs.data,
          (child) => {
            return child.id === payload.id
          }
        )
        // if meta pagination child more than 3
        if (state.conversations[parentIdx].childs.meta.total >= 3) {
          state.conversations[parentIdx].childs.meta.total++
          // if meta total same with child data length + 1. push child comment
          if (
            state.conversations[parentIdx].childs.meta.total ===
            state.conversations[parentIdx].childs.data.length + 1
          ) {
            // if total child meta total same with child data + 1 push the child conversation
            const updatedChildComment = state.conversations[parentIdx]
            if (childIdx < 0) updatedChildComment.childs.data.push(payload)
            state.conversations.splice(parentIdx, 1, updatedChildComment)
          }
        } else if (parentIdx >= 0) {
          const updatedChildComment = state.conversations[parentIdx]
          if (childIdx < 0) updatedChildComment.childs.data.push(payload)
          state.conversations.splice(parentIdx, 1, updatedChildComment)
        }
      } else if (typeof payload.reply === 'string') {
        // type string defined incoming push notif from agent (from chat panel). And update the total childs comment
        const parseReply = JSON.parse(payload.reply)
        const parentIdx = _.findIndex(state.conversations, function (o) {
          return o.id === parseReply.id
        })
        state.conversations[parentIdx].childs.meta.total++
      } else {
        pushConversationIgComment()
      }
    }

    const channel = payload.room.channel
    if (channel === 'ig_comment') {
      pushIgComment()
    } else {
      pushConversation()
    }
  },
  UPDATE_TEXT_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      // localVar.id = payload.type + moment().format('DDMMYYYYHHmmSSS')
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }

    const emailAttachment = []
    if (
      state.conversationInformation.channel === 'email' &&
      payload.attachments?.length
    ) {
      payload.attachments.forEach((data) => {
        emailAttachment.push({
          filename: data.split('/').pop(),
          url: data,
        })
      })
    }

    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
        email:
          state.conversationInformation.channel === 'email'
            ? { attachments: emailAttachment }
            : null,
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: this.$auth.user.avatar.large.url,
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
      reply: payload.reply,
    }

    if (state.conversationInformation.channel === 'email') {
      data.cc = state.payloadToSend.cc || []
      data.bcc = state.payloadToSend.bcc || []
      data.reply_to = state.payloadToSend.reply_to || []
    }

    state.conversations.push(data)
  },
  UPDATE_IMAGE_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      // localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [
                  {
                    filename: payload.file.name,
                    url: URL.createObjectURL(payload.file),
                  },
                ],
                attachments_type: payload.type,
              }
            : null,
        image: {
          filename: payload.file?.name || payload.urlDetail?.filename || '',
          large: payload.file_url,
          medium: payload.file_url,
          small: payload.file_url,
        },
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
      reply: payload.reply,
    }
    state.trigger.image = true
    state.conversations.push(data)
  },
  UPDATE_PRODUCT_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text || '',
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [
                  {
                    filename: payload.file.name,
                    url: URL.createObjectURL(payload.file),
                  },
                ],
                attachments_type: payload.type,
              }
            : null,
        image: {
          filename: payload.file?.name || payload.urlDetail?.filename || '',
          large: payload.file_url,
          medium: payload.file_url,
          small: payload.file_url,
        },
      },
      extra: payload.product || payload.products[0],
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
    }
    state.conversations.push(data)
  },
  UPDATE_ORDER_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text || '',
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [
                  {
                    filename: payload.file.name,
                    url: URL.createObjectURL(payload.file),
                  },
                ],
                attachments_type: payload.type,
              }
            : null,
        image: {
          filename: payload.file?.name || payload.urlDetail?.filename || '',
          large: payload.file_url,
          medium: payload.file_url,
          small: payload.file_url,
        },
      },
      extra: payload.order,
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
    }
    state.conversations.push(data)
  },
  UPDATE_INVOICE_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text || '',
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [
                  {
                    filename: payload.file.name,
                    url: URL.createObjectURL(payload.file),
                  },
                ],
                attachments_type: payload.type,
              }
            : null,
        image: {
          filename: payload.file?.name || payload.urlDetail?.filename || '',
          large: payload.file_url,
          medium: payload.file_url,
          small: payload.file_url,
        },
      },
      extra: payload.invoice,
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
    }
    state.conversations.push(data)
  },
  UPDATE_DOCUMENT_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      // localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }

    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [
                  {
                    filename: payload.file.name,
                    url: URL.createObjectURL(payload.file),
                  },
                ],
                attachments_type: payload.type,
              }
            : null,
        document: {
          filename: payload.file?.name || payload.urlDetail?.filename || '',
          url: payload.file_url,
          size: payload.file?.size
            ? (payload.file.size / 1000000).toFixed(2)
            : payload.urlDetail?.size
            ? (payload.urlDetail.size / 1000000).toFixed(2)
            : 0,
          filetype: payload.file?.type || payload.urlDetail?.filetype || '',
        },
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
      reply: payload.reply,
    }
    state.trigger.document = true
    state.conversations.push(data)
  },
  UPDATE_VIDEO_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      // localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [{ file: [payload.url] }],
                attachments_type: payload.type,
              }
            : null,
        video: {
          filename: payload.file?.name || payload.urlDetail?.filename || '',
          large: payload.file_url,
          medium: payload.file_url,
          small: payload.file_url,
          url: payload.file_url,
        },
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
      reply: payload.reply,
    }
    state.trigger.document = true
    state.conversations.push(data)
  },
  UPDATE_AUDIO_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      // localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: 'audio',
        text: payload.text,
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [
                  {
                    filename: payload.file.name,
                    url: URL.createObjectURL(payload.file),
                  },
                ],
                attachments_type: payload.type,
              }
            : null,
        voice: {
          filename: payload.file?.name || payload.urlDetail?.filename || '',
          url: payload.file_url,
        },
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
      reply: payload.reply,
    }
    state.trigger.document = true
    state.conversations.push(data)
  },
  UPDATE_LOCATION_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      // localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
        file: payload.file,
        url: payload.url,
        email:
          state.conversationInformation.channel === 'email'
            ? {
                attachments: [
                  {
                    filename: payload.file.name,
                    url: URL.createObjectURL(payload.file),
                  },
                ],
                attachments_type: payload.type,
              }
            : null,
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
      buttons: [],
      reply: payload.reply,
    }
    state.trigger.document = true
    state.conversations.push(data)
  },
  UPDATE_COMMENT_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      localVar.id = payload.id
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }

    const data = {
      id: localVar.id,
      type: 'text',
      room_id: payload.room_id,
      is_campaign: false,
      sender_id: this.$auth.user.id,
      sender_type: 'Models::Contact',
      sender: {
        name: this.$auth.user.full_name,
        avatar: this.$auth.user.avatar.large.url,
        username: '',
      },
      message: {
        type: payload.type,
        text: payload.text,
      },
      participant_id: null,
      organization_id: this.$auth.user.organization_id,
      text: payload.text,
      status: localVar.status,
      participant_type: 'agent',
      external_id: null,
      local_id: null,
      created_at: moment().toISOString(),
      reply: {},
      buttons: [],
      url: null,
      childs: {
        data: [],
        meta: {
          pagination: {
            cursor: {
              next: null,
              prev: null,
            },
            offset: 0,
            limit: 0,
            total: 0,
          },
        },
      },
    }
    state.conversations.push(data)
  },
  UPDATE_CONVERSATION_STATUS(state, payload) {
    try {
      const index = _.findIndex(state.conversations, { id: payload.id })
      if (index < 0 && payload.id) {
        state.conversations[payload.index].id = payload.id
        state.conversations[payload.index].status = payload.status
        state.conversations[payload.index].local = false
        if (payload.reply)
          state.conversations[payload.index].reply = payload.reply
        if (payload.extra)
          state.conversations[payload.index].extra = payload.extra
        if (payload.file) state.conversations[payload.index].file = payload.file
        if (payload.sender_id)
          state.conversations[payload.index].sender_id = payload.sender_id
      } else {
        state.conversations[index].id = payload.id
        state.conversations[index].status = payload.status
        state.conversations[index].local = false
        if (payload.reply) state.conversations[index].reply = payload.reply
        if (payload.extra) state.conversations[index].extra = payload.extra
        if (payload.file) state.conversations[index].file = payload.file
        if (payload.sender_id)
          state.conversations[index].sender_id = payload.sender_id
      }
    } catch (error) {
      // console.error(error) // dont throw new Error here just do console.error
    }
  },
  UPDATE_ASSIGNED_AGENT_NOTIFICATION(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index < 0) {
      const data = {
        id: payload.id,
        message: {
          type: '',
          text:
            '<b>' +
            payload.agent.full_name +
            '</b> has been assigned to this conversation',
        },
        created_at: moment().toISOString(),
        type: 'system',
        sender: payload.sender,
        status: '',
        agent: payload.agent,
      }
      state.conversations.push(data)
    }
  },
  REMOVE_ASSIGNED_AGENT_NOTIFICATION(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index < 0) {
      const data = {
        id: payload.id,
        message: {
          type: '',
          text:
            '<b>' +
            payload.agent.profile.name +
            '</b> has been removed from this conversation by <b>' +
            payload.sender.name +
            '</b>',
        },
        created_at: moment().toISOString(),
        type: 'system',
        sender: payload.sender,
        status: '',
        agent: payload.agent,
      }
      state.conversations.push(data)
    }
  },
  UPDATE_RESOLVED_NOTIFICATION(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index < 0) {
      const data = {
        id: payload.id,
        message: {
          type: '',
          text:
            'Conversation has been resolved by <b>' +
            payload.agent.full_name +
            '</b>',
        },
        created_at: moment().toISOString(),
        type: 'system',
        sender: {
          id: this.$auth.user.id,
          name: this.$auth.user.full_name,
          avatar: {
            small: '',
          },
        },
        status: '',
        agent: payload.agent,
      }

      state.conversations.push(data)
    }
  },
  UPDATE_BLOCK_CONTACT_NOTIFICATION(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index < 0) {
      const data = {
        id: payload.id,
        message: {
          type: '',
          text:
            '<b>' + payload.agent.profile.name + '</b> blocked this contact',
        },
        created_at: moment().toISOString(),
        type: 'system',
        sender: payload.sender,
        status: '',
        agent: payload.agent,
      }
      state.conversations.push(data)
    }
  },
  ASSIGN_AGENT(state, payload) {
    const index = _.findIndex(state.conversationInformation.agents, {
      id: payload.id,
    })
    if (index < 0) {
      state.conversationInformation.agents.push(payload)
    }
  },
  REMOVE_AGENT(state, payload) {
    const index = _.findIndex(state.conversationInformation.agents, {
      id: payload.id,
    })
    if (index >= 0) {
      state.conversationInformation.agents.splice(index, 1)
    }
  },
  REMOVE_ALL_AGENT(state) {
    state.conversationInformation.agents = []
  },
  UPDATE_CALL_PERMISSION_REQUEST(state, payload) {
    const childs = Array.isArray(payload.childs) ? payload.childs : []
    const channelIntegrationId =
      payload?.channelIntegrationId ??
      state.chatRoomDetails?.channelIntegrationId ??
      ''
    let childContactValue = null
    if (childs.length > 0) {
      childContactValue =
        childs.find(
          (item) => item.channel_integration_id === channelIntegrationId
        ) || null
    }
    state.callPermissionRequest = {
      contactId: payload.contactId,
      channelIntegrationId: payload.channelIntegrationId,
      childContact: childContactValue,
    }
  },
  UPDATE_CONVERSATION_INFORMATION(state, payload) {
    state.conversationInformation.id = payload.id
    state.conversationInformation.name = payload.name
    state.conversationInformation.description = payload.description
    state.conversationInformation.channel = payload.channel
    state.conversationInformation.channel_name = payload.channel_name
      ? payload.channel_name
      : ''
    state.conversationInformation.usageDetails.created = payload.created_at
    state.conversationInformation.usageDetails.last_seen = payload.session_at
    state.conversationInformation.usageDetails.session_at = payload.session_at
    state.conversationInformation.avatar = payload.avatar.large.url || ''
    state.conversationInformation.notes =
      payload.note === null ? payload.note : payload.note.text
    state.conversationInformation.tags = payload.tags
    state.conversationInformation.type = payload.type
    state.conversationInformation.resolved = payload.status === 'resolved'
    state.conversationInformation.status = payload.status
    state.conversationInformation.unread_count = payload.unread_count
    state.conversationInformation.is_dont_auto_resolve =
      payload.is_dont_auto_resolve
    state.conversationInformation.uniqueAccount = payload.account_uniq_id
    state.conversationInformation.channelAccount = payload.channel_account
    state.conversationInformation.channelIntegrationId =
      payload.channel_integration_id
    state.room.unread = payload.unread_count
    state.conversationInformation.isManualSurvey = payload.is_manual_survey
    state.conversationInformation.type = payload.type
    state.conversationInformation.description = payload.description
    state.conversationInformation.lastActivityAt = payload.last_activity_at

    calculateRoomSession(state, payload.session_at)
  },
  UPDATE_CHAT_ROOM_DETAILS(state, payload) {
    state.chatRoomDetails.accountUniqId = payload.account_uniq_id
    state.chatRoomDetails.name = payload.name
    state.chatRoomDetails.channel = payload.channel
    state.chatRoomDetails.channelAccountName = payload.channel_account
    state.chatRoomDetails.channelIntegrationId = payload.channel_integration_id
  },
  UPDATE_CONVERSATION_INFORMATION_CONTACT_ACCOUNT(state, payload) {
    state.conversationInformation.name = payload.name
    state.conversationInformation.email = payload.email
    state.conversationInformation.phone = payload.phone
  },
  UPDATE_CONVERSATION_INFORMATION_CONTACT_BLOCKED(state, payload) {
    state.isContactBlocked = payload
  },
  UPDATE_CONVERSATION_LAST_SEEN(state, payload) {
    state.conversationInformation.usageDetails.last_seen = payload

    // recalculate room session
    calculateRoomSession(state, payload)
  },
  UPDATE_ROOM_STATUS(state, payload) {
    state.conversationInformation.status = payload
  },
  EDIT_CONVERSATION_INFORMATION(state, payload) {
    state.conversationInformation[payload.type] = payload.value[payload.type]
  },
  EDIT_CONVERSATION_INFORMATION_NOTES(state, payload) {
    state.conversationInformation.notes = payload.note.text
  },
  SET_AGENT_LISTS(state, payload) {
    state.agentLists = payload
  },
  PUSH_AGENT_LISTS(state, payload) {
    payload.forEach((payload) => {
      state.agentLists.push(payload)
    })
  },
  UPDATE_MESSAGE_META(state, payload) {
    state.message = {
      length: payload.length,
      pop: payload.pop,
    }
  },
  UPDATE_MESSAGE_STATUS(state, payload) {
    if (state.conversations[payload.index].status !== payload.status) {
      state.conversations[payload.index].status = payload.status
    }
  },
  UPDATE_FORM_MESSAGE_IS_SUBMITTED(state, payload) {
    if (
      state.conversations[payload.index].is_submitted !==
      payload.data.is_submitted
    ) {
      state.conversations[payload.index].is_submitted =
        payload.data.is_submitted
      state.conversations[payload.index].form_response =
        payload.data.form_response
    }
  },
  REMOVE_CONVERSATION(state, payload) {
    if (state.message.length) {
      payload.splice(0, state.message.pop)
    }
    state.conversationsFormatted = payload
  },
  REMOVE_CONVERSATION_BY_ID(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index >= 0) {
      state.conversations.splice(index, 1)
    }
  },
  REMOVE_CONVERSATION_BY_INDEX(state, payload) {
    state.conversations.splice(payload.index, 1)
  },
  UPDATE_CONVERSATION(state, payload) {
    let mergedConversations = [...state.conversations, ...payload.conversations]

    mergedConversations = [
      ...new Map(mergedConversations.map((conv) => [conv.id, conv])).values(),
    ]

    state.conversations = _.sortBy(mergedConversations, (c) => c.created_at)
  },
  UPDATE_TRIGGER(state, payload) {
    state.trigger = {
      image: payload.image,
      document: payload.document,
    }
  },
  SET_META(state, payload) {
    state.meta = payload
  },
  SET_INITIAL_CONDITION(state) {
    state.status = {
      noConversation: false,
      noResults: false,
      error: false,
    }
    state.page = 1
    state.identifier++
    state.conversations = []
    state.message = {
      length: null,
      pop: 0,
    }
    state.meta = {}
    state.scroll.difference = null
    // state.room.unread = 0
  },
  UPDATE_PAGE(state, payload) {
    state.page = payload
  },
  UPDATE_STATUS(state, payload) {
    state.status.noConversation = payload.noConversation
    state.status.noResults = payload.noResults
    state.status.error = payload.error
  },
  UPDATE_INFINITE_LOADING(state, payload) {
    state.infinite = state.infinite + payload
  },
  UPDATE_SEND_STATUS(state, payload) {
    state.conversations[state.conversations.length - 1].status = payload
  },
  RESOLVE_CONV(state, payload) {
    state.conversationInformation.resolved = payload
  },
  UPDATE_CONV_BY_SCROLLING(state, payload) {
    for (const x in payload) {
      state.conversations.unshift(payload[x])
    }
  },
  UPDATE_HISTORY_BY_SCROLLING(state, payload) {
    for (const x in payload) {
      state.conversationInformation.roomHistory.push(payload[x])
    }
  },
  UPDATE_TAGS(state, payload) {
    state.conversationInformation.tags.push(payload)
  },
  UPDATE_EDIT_MODE(state, payload) {
    state.editMode[payload.type] = payload.value
  },
  UPDATE_QUERY_ROOM(state, payload) {
    state.query.room = payload
  },
  REMOVE_TAG(state, payload) {
    state.conversationInformation.tags.splice(payload, 1)
  },
  EMPTY_TAG(state, payload) {
    state.conversationInformation.tags = []
  },
  UPDATE_PARTICIPANT_LIST(state, payload) {
    state.conversationInformation.agents = payload
  },
  UPDATE_ALL_ROOM_PARTICIPANTS(state, payload) {
    state.conversationInformation.participants = payload
  },
  REMOVE_PARTICIPANT(state, payload) {
    const index = _.findIndex(state.conversationInformation.participants, {
      contact_able_id: payload.contact_able_id,
    })
    if (index >= 0) {
      state.conversationInformation.participants.splice(index, 1)
    }
  },
  UPDATE_SCROLL(state, payload) {
    state.scroll.difference = payload
  },
  UPDATE_UNREAD_IN_ROOM(state, payload) {
    state.room.unread = payload
  },
  UPDATE_CONVERSATION_SESSION_STATUS(state, payload) {
    state.conversationInformation.session = payload
  },
  REMOVE_CONV_INFORMATION(state) {
    state.conversationInformation = {
      id: '',
      name: '',
      channel: '',
      email: '',
      phone: '',
      usageDetails: {
        created: '',
        last_seen: '',
        location: '',
        device: '',
        url_referrer: '',
        session_expired: '',
      },
      avatar: '',
      notes: '',
      tags: [],
      properties: [],
      agents: [],
      participants: [],
      resolved: false,
      session: '',
      last_message: {
        text: '',
        type: '',
      },
      unread_count: 0,
      roomHistory: [],
      isManualSurvey: false,
    }
  },
  UPDATE_ROOM_HISTORY(state, payload) {
    state.conversationInformation.roomHistory.push(...payload)
  },
  CREATE_TEMPORARY_ID(state, payload) {
    state.temporaryRoomId = payload
  },
  SET_EDIT_CONTACT(state, payload) {
    state.contact = payload
  },
  UPDATE_CONVERSATION_CONTACT_INFORMATION(state, payload) {
    state.conversationInformation.email =
      payload.email === null ? '' : payload.email
    state.conversationInformation.phone =
      payload.phone_number === null ? '' : payload.phone_number
    state.conversationInformation.contactId =
      payload.contactId === null ? '' : payload.id
    state.isContactBlocked = payload.is_blocked
  },
  UPDATING_READ_ALL(state, payload) {
    state.room.isReadingAllMessages = true
  },
  UPDATED_READ_ALL(state, payload) {
    state.room.isReadingAllMessages = false
  },
  UPDATE_CONVERSATION_AVATAR(state, payload) {
    const { index, avatar } = payload
    state.conversations[index].sender.avatar = avatar
  },
  SET_ROOM_TICKET(state, payload) {
    state.roomTicket = payload
  },
  UPDATE_IS_DONT_AUTO_RESOLVE(state, payload) {
    state.conversationInformation.is_dont_auto_resolve = payload
  },
  ENQUEUE_MESSAGE(state, payload) {
    state.messageQueue.push(payload)
  },
  DEQUEUE_MESSAGE(state, dispatch) {
    state.isQueuing = true
  },
  UPDATE_ISQUEUING(state, payload) {
    state.isQueuing = payload
  },
  SHIFT_MESSAGE_QUEUING(state, payload) {
    state.messageQueue.splice(0, 1)
  },
  REMOVE_CONVERSATION_INFORMATION_ID(state) {
    state.conversationInformation.id = null
  },
  SET_CONTACT_DETAIL_DATA(state, payload) {
    state.contactDetailData = payload
  },
  UPDATE_CONTACT_DETAIL_DATA_PARAMS(state, payload) {
    state.contactDetailData[payload.type] = payload.value
  },
  SET_CONTACT_DETAIL_DATA_CUSTOM_FIELD(state, payload) {
    if (payload.response.length) {
      state.contactCustomFields = payload.response
    } else {
      state.contactCustomFields = []
    }
  },
  async SET_ACTIVE_ROOM_DATA(state, payload) {
    state.activeRooms = payload
    await calculateRoomSessionContact(state)
  },
  UPDATE_ACTIVE_ROOM_DATA(state, payload) {
    state.activeRooms = payload
  },
  REMOVE_RED_DOT_ACTIVE_ROOM_DATA(state, payload) {
    for (let i = 0; i < state.activeRooms.length; i++) {
      const el = state.activeRooms[i]
      if (el.id === payload.id) {
        el.status = 'resolved'
      }
    }
    state.reRender++
  },
  RE_RENDER(state, payload) {
    state.reRender++
  },
  UPDATE_REOPEN_ROOM(state, payload) {
    state.conversationInformation.status = payload.status
    if (payload.status !== 'resolved') {
      state.conversationInformation.resolved = false
    } else {
      state.conversationInformation.resolved = true
    }
  },
  REPLY_A_COMMENT(state, payload) {
    const childContent = {
      id: `${moment().format('DDMMMMYYYYHHmmSS') + payload.idx}`,
      type: 'text',
      sender: {
        name: this.$auth.user.full_name,
        avatar: this.$auth.user.avatar.large.url,
        username: '',
      },
      text: payload.username ? `@${payload.username} ` : '',
      status: 'edit',
      participant_type: 'agent',
      external_id: `${moment().format('DDMMMMYYYYHHmmSS') + payload.idx}`,
      created_at: moment().toISOString(),
    }
    if (payload.idx < 1)
      state.conversations[payload.msgIndex].childs.data.push(childContent)
    else
      state.conversations[payload.msgIndex].childs.data.splice(
        payload.index,
        0,
        childContent
      )
  },
  UPDATE_CHILD_MESSAGE_OBJECT(state, payload) {
    state.conversations[payload.parentIdx].childs.data[payload.childIdx][
      payload.obj
    ] = payload.value
  },
  REMOVE_INPUT_INDEX_ON_CHILD(state, payload) {
    state.conversations[payload.parentIdx].childs.data.splice(
      payload.childIdx,
      1
    )
  },
  PUSH_MORE_REPLIES_COMMENTS(state, payload) {
    const parentIdx = _.findIndex(state.conversations, { id: payload.reply_id })
    if (parentIdx >= 0) {
      state.conversations[parentIdx].childs.data = []
      state.conversations[parentIdx].childs.meta = {}
      state.conversations[parentIdx].childs.data.push(...payload.data.data)
      state.conversations[parentIdx].childs.meta = payload.data.meta
    }
  },
  PUSH_LOAD_MORE_REPLIES_COMMENT(state, payload) {
    const parentIdx = _.findIndex(state.conversations, {
      id: payload.params.reply_id,
    })
    if (parentIdx >= 0) {
      state.conversations[parentIdx].childs.data.push(...payload.data.data)
      state.conversations[parentIdx].childs.meta.offset =
        payload.data.meta.pagination.offset
    }
  },
  UPDATE_REPLY_MESSAGE(state, payload) {
    state.replyMessage = payload
  },
  UPDATE_MESSAGE_BY_ID(state, payload) {
    const type = payload?.type
    const idx = _.findIndex(state.conversations, {
      id: payload.id,
    })
    const newValue = state.conversations[idx]
    if (type === 'image') {
      newValue.message.image.size = payload?.file?.size
      newValue.message.image.large = payload?.file?.large?.url
      newValue.message.image.filename = payload?.file?.filename
      state.conversations.splice(idx, 1, newValue)
    }
    if (type === 'video') {
      newValue.message.video.url = payload?.file?.url
      newValue.message.video.size = payload?.file?.size
      newValue.message.video.large = payload?.file?.large?.url
      newValue.message.video.filename = payload?.file?.filename
      state.conversations.splice(idx, 1, newValue)
    }
  },
  UPDATE_CONVERSATION_INFORMATION_BY_FIELD(state, payload) {
    state.conversationInformation[payload.field] = payload.data
  },
  UPDATE_LOAD_MORE_CONVERSATION(state, payload) {
    const merged = [...state.conversations]
    payload.forEach((conv) => {
      conv.read_only = true
      merged.unshift(conv)
    })
    state.conversations = merged
  },
  UPDATE_COMBINE_CONV_AND_CHAT_HISTORY(state, payload) {
    state.isCombineConvAndChatHistory = payload
  },
  UPDATE_ROOM_SLA(state, payload) {
    state.conversationInformation = {
      ...state.conversationInformation,
      ...payload,
    }
  },
  UPDATE_SLA_INBOX_STATUS(state, payload) {
    state.enable_sla_inbox = payload
  },
  UPDATE_MESSAGE_TEXT(state, payload) {
    state.conversations[payload.index].message.text = payload.text
    state.conversations[payload.index].text = payload.text
  },
  UPDATE_MESSAGE_ID(state, payload) {
    state.conversations[payload.index].id = payload.id
  },
  UPDATE_GBR_LOCATION_DETAILS(state, payload) {
    state.conversationInformation = {
      ...state.conversationInformation,
      gbr: payload,
    }
  },
}

const calculateRoomSession = (state, sessionTime) => {
  const lastSession = new Date(sessionTime)
  const expiredSession = new Date(sessionTime)

  if (state.conversationInformation.channel === 'livechat_dot_com') {
    expiredSession.setHours(lastSession.getHours() + 1)
  } else {
    expiredSession.setDate(lastSession.getDate() + 1)
  }

  // difference between expired session and current time in minutes
  const now = new Date()
  const different = (expiredSession - now) / 1000 / 60

  // Now only wa and wa cloud that has session
  // if (state.conversationInformation.channel === 'livechat_dot_com') {
  //   if (different / 60 > 0.5) state.conversationInformation.session = 'open'
  //   else if (different / 60 > 0 && different / 60 <= 0.5)
  //     state.conversationInformation.session = 'expiring'
  // } else if (state.conversationInformation.channel !== 'livechat_dot_com') {
  //   if (different / 60 > 8) state.conversationInformation.session = 'open'
  //   else if (different / 60 > 0 && different / 60 <= 8)
  //     state.conversationInformation.session = 'expiring'
  // }
  // if (different < 0) state.conversationInformation.session = 'expired'

  if (
    (state.conversationInformation.channel === 'wa' ||
      state.conversationInformation.channel === 'wa_cloud' ||
      state.conversationInformation.channel === 'fb' ||
      state.conversationInformation.channel === 'ig') &&
    state.conversationInformation.type !== 'Models::GroupServiceRoom'
  ) {
    if (different / 60 > 8) state.conversationInformation.session = 'open'
    else if (different / 60 > 0 && different / 60 <= 8)
      state.conversationInformation.session = 'expiring'
    else if (different < 0) state.conversationInformation.session = 'expired'
  } else {
    state.conversationInformation.session = null
  }

  if (state.conversationInformation.session !== 'expired') {
    state.roomSession = expiredSession
  } else {
    state.roomSession = lastSession
  }
}

const calculateRoomSessionContact = (state) => {
  // calculated based on calculateRoomSession in conversation store
  for (let i = 0; i < state.activeRooms.length; i++) {
    const activeRoom = state.activeRooms[i]
    const lastSession = new Date(activeRoom.session_at)
    const expiredSession = new Date(activeRoom.session_at)
    if (activeRoom.channel === 'livechat_dot_com') {
      expiredSession.setHours(lastSession.getHours() + 1)
    } else {
      expiredSession.setDate(lastSession.getDate() + 1)
    }

    const now = new Date()
    const difference = (expiredSession - now) / 1000 / 60

    // Now only wa and wa cloud that has session
    // if (activeRoom.channel === 'livechat_dot_com') {
    //   if (difference / 60 > 0.5) activeRoom.session = 'open'
    //   else if (difference / 60 > 0 && difference / 60 <= 0.5)
    //     activeRoom.session = 'expiring'
    // } else if (activeRoom.channel !== 'livechat_dot_com') {
    //   if (difference / 60 > 8) activeRoom.session = 'open'
    //   else if (difference / 60 > 0 && difference / 60 <= 8)
    //     activeRoom.session = 'expiring'
    // }
    // if (
    //   difference < 0 &&
    //   activeRoom.channel !== 'telegram' &&
    //   activeRoom.channel !== 'line' &&
    //   activeRoom.channel !== 'twitter' &&
    //   activeRoom !== 'email'
    // ) {
    //   activeRoom.session = 'expired'
    // } else {
    //   activeRoom.session = 'open'
    // }

    if (
      activeRoom.channel === 'wa' ||
      activeRoom.channel === 'wa_cloud' ||
      activeRoom.channel === 'fb' ||
      activeRoom.channel === 'ig'
    ) {
      if (difference / 60 > 8) activeRoom.session = 'open'
      else if (difference / 60 > 0 && difference / 60 <= 8)
        activeRoom.session = 'expiring'
      else if (difference < 0) activeRoom.session = 'expired'
    } else {
      activeRoom.session = null
    }

    // if (activeRoom.session !== 'expired') {
    //   state.activeRoom.roomSession = expiredSession
    // } else {
    //   state.activeRoom.roomSession = lastSession
    // }
  }
}
